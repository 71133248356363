<template>
  <div>
    <section
      v-if="user.user_group && user.user_group.libelle == 'Administrateur'"
      id="dashboard-analytics"
    >
      <b-row class="match-height">
        <b-col>
          <b-card
            class="cursor"
            @click="$router.push('/parametres/clients')"
            body-class="pb-50"
          >
            <h6>Clients</h6>
            <h2 class="font-weight-bolder mb-1">
              {{data.customersCount ? ((data.customersCount >= 10 ? "":"0") + data.customersCount) : '--' }}
            </h2>
          </b-card>
        </b-col>
        <b-col>
          <b-card
            class="cursor"
            @click="$router.push('/associations')"
            body-class="pb-50"
          >
            <h6>Associations</h6>
            <h2 class="font-weight-bolder mb-1">
              {{data.associationsCount ? ((data.associationsCount >= 10 ? "":"0") + data.associationsCount): '--' }}
            </h2>
          </b-card>
        </b-col>
        <b-col>
          <b-card
            class="cursor"
            @click="$router.push('/manifestes')"
            body-class="pb-50"
          >
            <h6>Manifestes</h6>
            <h2 class="font-weight-bolder mb-1">
              {{ data.manifestesCount ? ((data.manifestesCount >= 10 ? "":"0") + data.manifestesCount) : '--' }}
            </h2>
          </b-card>
        </b-col>
        <b-col>
          <b-card
            class="cursor"
            @click="$router.push('/vehicules')"
            body-class="pb-50"
          >
            <h6>Véhicules</h6>
            <h2 class="font-weight-bolder mb-1">
              {{data.vehiculesCount? ((data.vehiculesCount >= 10 ? "":"0")  + data.vehiculesCount) : '--' }}
            </h2>
          </b-card>
        </b-col>
        <b-col>
          <b-card
            class="cursor"
            @click="$router.push('/personnels/membres')"
            body-class="pb-50"
          >
            <h6>Personnel</h6>
            <h2 class="font-weight-bolder mb-1">
              {{ data.personnelCount? ((data.personnelCount >= 10 ? "":"0") + data.personnelCount) : '--' }}
            </h2>
          </b-card>
        </b-col>
      </b-row>

      <b-row>
        <b-col>
          <b-card title="DERNIERS VEHICULES ENREGISTRES" body-class="pb-50">
            <b-table
              responsive
              :fields="headers"
              :items="data.vehicules"
              per-page="5"
              current-page="1"
              show-empty
              class="border-bottom"
            >
              <template #cell(date_arrivee)="data">
                <span>
                  {{ data.index + 1 }}
                  <!-- data.item.manifeste.date_arrivee
                    ? data.item.manifeste.date_arrivee
                    : "----" -->
                </span>
              </template>

              <template #cell(numero_manif)="data">
                <b-button size="sm" variant="info" @click="goto(data.item)">
                  {{ data.item.manifeste.numero_manif }}
                </b-button>
              </template>

              <template #cell(statut)="data">
                <b-badge
                  class="white--text"
                  :variant="
                    data.item.statut == 'en stock'
                      ? 'primary'
                      : data.item.statut == 'en dépôt'
                      ? 'error'
                      : data.item.statut == 'en attente'
                      ? 'warning'
                      : data.item.statut == 'en cours de vente'
                      ? 'secondary'
                      : data.item.statut == 'vendu'
                      ? 'primary'
                      : 'success'
                  "
                  small
                >
                  {{ data.item.statut }}
                </b-badge>
              </template>

              <template #cell(actions)="data">
                <div>
                  <b-button
                    :id="'button-3' + data.item.id"
                    v-if="$hasFonction(user, 'Voir véhicule')"
                    size="sm"
                    variant="flat-primary"
                    class="btn-icon"
                    @click="showItem(data.item)"
                  >
                    <feather-icon icon="EyeIcon" />
                  </b-button>
                </div>
              </template>
            </b-table>
          </b-card>
        </b-col>
        <b-col>
          <b-card title="EVOLUTION DES VENTES" body-class="pb-50">
            <vue-apex-charts
              type="line"
              height="365"
              :options="salesLine.chartOptions"
              :series="salesLine.series ? salesLine.series : []"
            />
          </b-card>
        </b-col>
      </b-row>
    </section>
    <div v-if="user.user_group && user.user_group.libelle != 'Administrateur'">
      <b-row class="match-height">
        <b-col lg="12" md="12">
          <b-card text-variant="center" class="card card-congratulations">
            <b-avatar variant="primary" size="70" class="shadow mb-2">
              <feather-icon size="28" icon="AwardIcon" />
            </b-avatar>
            <h1 class="mb-1 mt-50 text-white">Bonjour {{ user.name }}</h1>
            <b-card-text class="m-auto w-75">
              Bienvenue sur <strong>FLASHCAR : </strong>
              Application de gestion de parc automobile
            </b-card-text>
          </b-card>
        </b-col>
        <b-col lg="12" md="12">
          <!-- style="max-height: 800px" -->
          <b-card no-body>
            <b-img fluid :src="imgUrl" alt="association détail" />
          </b-card>
        </b-col>
        <b-col lg="3" sm="6"> </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import VueApexCharts from "vue-apexcharts";
import { $themeColors } from "@themeConfig";
import {
  BCard,
  BCardBody,
  BButton,
  BTable,
  BCardHeader,
  BImg,
  BCardText,
  BCardTitle,
  BMedia,
  BMediaAside,
  BAvatar,
  VBTooltip,
  BMediaBody,
  BRow,
  BCol,
  BBadge,
} from "bootstrap-vue";
export default {
  components: {
    BCard,
    BBadge,
    BImg,
    VueApexCharts,
    BCardBody,
    BButton,
    BCardHeader,
    BCardText,
    BCardTitle,
    BTable,
    BMedia,
    BMediaAside,
    BAvatar,
    BMediaBody,
    BRow,
    BCol,
    // LineChart,
  },
  directives: {
    "b-tooltip": VBTooltip,
  },

  created() {
    this.getVehicules();
  },

  async mounted() {
    // this.renderChart(this.chartdata, this.options);
    this.loaded = false;

    this.$http.get("/home").then((response) => {
      this.data = response.data;
      this.vehicules = response.data.vehicules;
      this.salesLine.series = [
        {
          name: "Ventes",
          data: response.data.factures,
        },
      ];
    });

    // try {
    //   const { userlist } = await fetch("/api/userlist");
    //   this.chartdata = userlist;
    //   this.loaded = true;
    // } catch (e) {
    //   console.error(e);
    // }
  },
  computed: {
    ...mapState({
      user: (state) => state.user.auth,
    }),

    imgUrl() {
      return require("@/assets/images/aff.jpg");
    },
  },
  methods: {
    goto(item) {
      window.sessionStorage.setItem("manifeste_data", JSON.stringify(item.manifeste));
      this.$router.push({
        name: "manifesteDetails",
      });
    },
    showItem(item) {
      this.$router.push({
        name: "vehicules-details",
        params: { id: item.id },
      });
    },
    getVehicules() {
      this.$http
          .get("/vehicules")
          .then((res) => {
            if (res.status) {
              this.hiddenLoader = true;
            }
            this.vehicules = res.data.data;
            this.pricesReview();

            if (this.vehiculesList.length) {
              this.hidden = false;
              this.hiddenEmpty = true;
            } else {
              this.hidden = true;
              this.hiddenEmpty = false;
            }
            this.allRows = this.vehicules.length
          })
          .catch((e) => {
            console.log("erreur de récupération:" + e);
          });
    },

  },
  data() {
    return {
      loaded: false,
      allVehicules: [],
      totalvehicile: 0,
      salesLine: {
        chartOptions: {
          chart: {
            toolbar: { show: false },
            zoom: { enabled: false },
            type: "line",
            dropShadow: {
              enabled: true,
              top: 18,
              left: 2,
              blur: 5,
              opacity: 0.2,
            },
            offsetX: -10,
          },
          stroke: {
            curve: "smooth",
            width: 4,
          },
          grid: {
            borderColor: "#ebe9f1",
            padding: {
              top: -20,
              bottom: 5,
              left: 20,
            },
          },
          legend: {
            show: false,
          },
          colors: ["#df87f2"],
          fill: {
            type: "gradient",
            gradient: {
              shade: "dark",
              inverseColors: false,
              gradientToColors: [$themeColors.primary],
              shadeIntensity: 1,
              type: "horizontal",
              opacityFrom: 1,
              opacityTo: 1,
              stops: [0, 100, 100, 100],
            },
          },
          markers: {
            size: 0,
            hover: {
              size: 5,
            },
          },
          xaxis: {
            labels: {
              offsetY: 5,
              style: {
                colors: "#b9b9c3",
                fontSize: "0.857rem",
              },
            },
            axisTicks: {
              show: false,
            },
            categories: [
              "Jan",
              "Fev",
              "Mar",
              "Avr",
              "Mai",
              "Jun",
              "Jul",
              "Aut",
              "Sep",
              "Oct",
              "Nov",
              "Dec",
            ],
            axisBorder: {
              show: false,
            },
            tickPlacement: "on",
          },
          yaxis: {
            tickAmount: 5,
            labels: {
              style: {
                colors: "#b9b9c3",
                fontSize: "0.857rem",
              },
              formatter(val) {
                return val > 999 ? `${(val / 1000).toFixed(1)}k` : val;
              },
            },
          },
          tooltip: {
            x: { show: false },
          },
        },
      },
      data: {},
      headers: [
        { label: "Manifeste", key: "numero_manif", sortable: true },
        { label: "Chassis", key: "numero_chassis", sortable: true },
        { label: "Marque", key: "marque", sortable: true },
        { label: "Statut", key: "statut" },
        { label: "Actions", key: "actions", sortable: false },
      ],
      vehicules: [],
    };

  },


};
</script>

<style lang="scss">
.cursor {
  cursor: pointer;
}
.cursor:hover {
  background-color: rgba(255, 153, 0, 0.479);
  color: #fff !important;
}
</style>